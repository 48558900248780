.input-range__slider {
    appearance: none;
    background: url("../images/scale-slider-runner.svg") center center no-repeat;
    cursor: pointer;
    display: block;
    width: 13px;
    height: 16px;
    margin-left: -6px;
    margin-top: -9px;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.input-range__slider:active {
    /*transform: scale(1.3); */
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }

.input-range--disabled .input-range__slider {
    opacity: 0.8;
    box-shadow: none;
    transform: none; }

.input-range__slider-container {
    transition: left 0.3s ease-out; }

.input-range__label {
    display: none;
    color: #aaaaaa;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
    bottom: -1.4rem;
    position: absolute; }

.input-range__label--min {
    left: 0; }

.input-range__label--max {
    right: 0; }

.input-range__label--value {
    position: absolute;
    top: -1.8rem; }

.input-range__label-container {
    left: -50%;
    position: relative; }

.input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
    background: rgba(255,255,255,0.08);
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 2px;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out; }

.input-range--disabled .input-range__track {
    background: transparent; } /*#cccccc*/

.input-range__track--background {
    left: 0;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 50%; }

.input-range__track--active {
    background: transparent; }  /*#3f51b5*/

.input-range {
    height: 1px; /*1rem*/
    position: relative;
    width: 100%; }

/*# sourceMappingURL=index.css.map */