/* This stylesheet generated by Transfonter (https://transfonter.org) on January 10, 2018 10:07 AM */

body {
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  background: #1B1C21;
  color: #ffffff;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
    border-radius: 18px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.32);
    border-radius: 18px;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Black.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Black.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Bold.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Hairline.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Heavy.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Light.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Light.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Medium.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Medium.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Semibold.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Thin.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato/Lato-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Lato/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Thin.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Lato/Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu/Ubuntu-Bold.eot');
  src: url('./fonts/Ubuntu/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu/Ubuntu-Bold.woff2') format('woff2'),
  url('./fonts/Ubuntu/Ubuntu-Bold.woff') format('woff'),
  url('./fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype'),
  url('./fonts/Ubuntu/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu/Ubuntu-Light.eot');
  src: url('./fonts/Ubuntu/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu/Ubuntu-Light.woff2') format('woff2'),
  url('./fonts/Ubuntu/Ubuntu-Light.woff') format('woff'),
  url('./fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype'),
  url('./fonts/Ubuntu/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu/Ubuntu-Regular.eot');
  src: url('./fonts/Ubuntu/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu/Ubuntu-Regular.woff2') format('woff2'),
  url('./fonts/Ubuntu/Ubuntu-Regular.woff') format('woff'),
  url('./fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype'),
  url('./fonts/Ubuntu/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu/Ubuntu-Medium.eot');
  src: url('./fonts/Ubuntu/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Ubuntu/Ubuntu-Medium.woff2') format('woff2'),
  url('./fonts/Ubuntu/Ubuntu-Medium.woff') format('woff'),
  url('./fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype'),
  url('./fonts/Ubuntu/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

